import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout/layout';
import Seo from '../components/seo';
import MainScreenGuest from '../components/UiComponents/MainScreenGuest/MainScreenGuest';
import {
  CrumbsNav,
  DirectorInnerPortfolio,
} from '../components/Pages/DirectorInner';

import { directorInnerStatic } from '../db/directorInnerStatic';

const DirectorGuest = ({data}) => {
  
  return (
    <Layout>
      <Seo 
        title={data.strapiGuestDirectors.seo_title} 
        description={data.strapiGuestDirectors.seo_description}
      /> 
      <MainScreenGuest
        title={data.strapiGuestDirectors.title}
        videoCover={data.strapiGuestDirectors.video_cover}
      />

      <DirectorInnerPortfolio
        portfolioData={data.strapiGuestDirectors.portfolio}
        tickerData={directorInnerStatic.tickers}
      />
      
      <CrumbsNav 
        dataCrumbs={directorInnerStatic.crumbsNav} 
        link={"/guest-directors/"}
        firstTitle={'Guest Directors'}
      /> 
    </Layout>
  );
};

export default DirectorGuest;


export const query = graphql`
  query DirectorGuest {
    strapiGuestDirectors {
    seo_title
    seo_description
    title
    video_cover {
      url
    }
    portfolio {
      title
      link
      video {
        url
      }
    }
    }
  }
`;
